<template>
  <div>
    <md-table :table-header-color="tableHeaderColor">
      <md-table-row v-for="item in topChannelData" :key="item.ID">
        <md-table-cell md-label="Channel">{{ item.title }}</md-table-cell>
        <md-table-cell md-label="Count">{{ item.count }}</md-table-cell>
        <!-- <md-table-cell md-label="ID">{{ item.id }}</md-table-cell> -->
      </md-table-row>
    </md-table>
  </div>
</template>
<!-- 
<div>
  <md-table v-model="topChannelData" :table-header-color="tableHeaderColor">
    <md-table-row slot="md-table-row" slot-scope="{ item }">
      <md-table-cell md-label="Channel">{{ item.channel }}</md-table-cell>
      <md-table-cell md-label="Count">{{ item.count }}</md-table-cell>
      <md-table-cell md-label="ID">{{ item.ID }}</md-table-cell>
    </md-table-row>
  </md-table>
</div> -->

<script>
export default {
  name: "top-channels-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
    topChannelData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selected: [],
    };
  },
};
</script>
