<template>
    <div class="md-layout">
    
    
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="blue">
                <template slot="header">
        <div class="card-icon">
            <md-icon>link</md-icon>
        </div>
        <h3 class="title">
            <h3 class="title">Umu-Links</h3>
        
            <p>
                <md-button class="md-sm md-primary"><a href="https://ummiume.mapresso.com/" class="btn btn-primary mr-2 mb-2" title="" target="_blank">UmMiUme</a
                            ></md-button
                          >
            
                          <md-button class="md-sm md-info"
                            ><a
                              href="https://ummiume.mapresso.com/backend/server/do_monitoring.php"
                              class="btn btn-primary mr-2 mb-2"
                              title="" target="_blank"
                              >Monitoring</a
                            ></md-button
                          >
            
                          <md-button class="md-sm" 
                            ><a
                              href="https://ummiume.mapresso.com/backend/server/daily_update.php"
                              class="btn btn-primary mr-2 mb-2"
                              title="" target="_blank"
                              >Update</a
                            ></md-button
                          >
            
                          <md-button class="md-sm"
                            ><a
                              href="https://ummiume.mapresso.com/backend/server/integrity.php?mincode=400"
                              class="btn btn-primary mr-2 mb-2"
                              title="" target="_blank"
                              >Integrity</a
                            ></md-button
                          >
            
                          <md-button class="md-sm"
                            ><a
                              href="https://ummiume.mapresso.com/backend/server/utils/umu_cleanup_cache.php"
                              class="btn btn-primary mr-2 mb-2"
                              title="" target="_blank"
                              >Cleanup Cache</a
                            ></md-button
                          >
                          <md-button class="md-sm"
                            ><a
                              href="https://ummiume.mapresso.com/backend/server/utils/umu_empty_cache.php"
                              class="btn btn-primary mr-2 mb-2"
                              title="" target="_blank"
                              >Empty Cache</a
                            ></md-button
                          >
                          <md-button class="md-sm"
                            ><a
                              href="http://localhost:8888/backend/server/home.php"
                              class="btn btn-secondary mr-2 mb-2"
                              title=""
                              >Admin local</a
                            ></md-button
                          >
                        </p>
                      </h3>
</template>

<template slot="footer">
    <div class="stats">
        <md-icon>build</md-icon>
        For internal use only.
    </div>
</template>
      </stats-card>

            <chart-card header-animation="false" :chart-data="pieChart.data" :chart-options="pieChart.options" chart-type="Pie" header-icon chart-inside-content background-color="green">
<template slot="chartInsideHeader">
    <div class="card-icon">
        <md-icon>pie_chart</md-icon>
    </div>
    <h3 class="title">Climate Diagrams</h3>
</template>

<template slot="footer">
    <div class="md-layout">
    
        <div class="md-layout-item">
            <h3 class="title">{{ stat.climatediagrams }} --- {{ Math.round(100 * climaproz) / 100 }}%
            </h3>
        </div>
    </div>
</template>
        </chart-card>

            
      <stats-card header-color="rose">
<template slot="header">
    <div class="card-icon">
        <md-icon>equalizer</md-icon>
    </div>
    <p class="category">Climate Diagrams</p>
    <h3 class="title">{{ stat.climatediagrams }}</h3>
    <h3 class="title">
        {{ Math.round(100 * climaproz) / 100 }}%
    </h3>
</template>

<template slot="footer">
    <div class="stats">
        <md-icon>local_offer</md-icon>
        Based on the log entries. </div>
</template>
      </stats-card>

            <meteo-card header-color="green" :luftdaten="luftdatenVolta"></meteo-card>
      <meteo-card header-color="green" :luftdaten="luftdatenLax"></meteo-card>


      <stats-card header-color="green">
<template slot="header">
    <div class="card-icon">
        <md-icon>equalizer</md-icon>
    </div>
    <p class="category">Channels</p>
    <h3 class="title">{{ stat.channels }}</h3>
    <p class="category">Mapsites</p>
    <h3 class="title">{{ stat.mapsites }}</h3>
    <p class="category">Locations</p>
    <h3 class="title">{{ stat.locations }}</h3>
    <p class="category">Items in DB</p>
    <h3 class="title">{{ stat.items }}</h3>
    <p class="category">Umu Log Records</p>
    <h3 class="title">{{ stat.umulogrecords }}</h3>
    <p class="category">Center (last 1000)</p>
    <p class="category">
        <small>{{ stat.place }}</small>
    </p>
    <h4 class="title"><a :href="gmlink()" target="_blank">
                        {{ Math.round(100 * stat.mean_lat_1000) / 100 }} N /
                        {{ Math.round(100 * stat.mean_lon_1000) / 100 }} E
                      </a></h4>
</template>

<template slot="footer">
    <div class="stats">
        <md-icon>date_range</md-icon>
        Current Status. Click below address to see a map of the center. </div>
</template>
      </stats-card>

      <stats-card header-color="black">
<template slot="header">
    <div class="card-icon">
        <md-icon>link</md-icon>
    </div>
    <h3 class="title">
        <h3 class="title">Links</h3>
    
        <p>
            <md-button class="md-sm">
                <a href="http://my.mapresso.com//pontocam" title="" target="_blank">Webcams TI</a
                            >
                          </md-button>
            
                          <md-button class="md-sm">
                            <a
                              href="http://my.mapresso.com/locateme.html"
                              title=""
                              target="_blank"
                              >Landesmuseum</a
                            >
                          </md-button>
            
                          <md-button class="md-sm">
                            <a
                              href="http://my.mapresso.com/superkondi"
                              title=""
                              target="_blank"
                              >Superkondi</a
                            >
                          </md-button>
            
                          <md-button class="md-sm">
                            <a href="http://mapresso.com/div/sola" title="" target="_blank"
                              >SOLA</a
                            >
                          </md-button>
            
                          <md-button class="md-sm">
                            <a href="/phpinfo.php" title="" target="_blank">phpinfo</a>
            </md-button>
    
            <md-button class="md-sm">
                <a href="http://my.mapresso.com/luftdaten" title="" target="_blank">Luftdaten</a
                            >
                          </md-button>
            
                          <md-button class="md-sm">
                            <a href="http://mapresso.com/adi.html" title="" target="_blank"
                              >Projekte</a
                            >
                          </md-button>
            
                          <md-button class="md-sm">
                            <a
                              href="https://observablehq.com/@aquilo/ebaugesuche"
                              title=""
                              target="_blank"
                              >ObservableHQ</a
                            >
                          </md-button>
            
                          <md-button class="md-sm">
                            <a href="/sudoku" title="" target="_blank">Sudoku</a>
            </md-button>
    
            <md-button class="md-sm">
                <a href="/nutrition" title="" target="_blank">Nutrition</a>
            </md-button>
    
            <md-button class="md-sm">
                <a href="/galleryevaluation/eval.html" title="" target="_blank">Gallery Evaluation</a
                            >
                          </md-button>
                        </p>
                      </h3>
</template>

<template slot="footer">
    <div class="stats">
        <md-icon>update</md-icon>
        Tutto misto. </div>
</template>
      </stats-card>



      

    </div>

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <md-card>
        <md-card-header data-background-color="green">
          <h4 class="title">Top Channels</h4>
          <p class="category">New employees on 15th September, 2016</p>
        </md-card-header>
        <md-card-content>
          <top-channels-table
            table-header-color="orange"
            :top-channel-data="tcd"
          ></top-channels-table>
        </md-card-content>
      </md-card>
    </div>

    

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <md-card>
        <md-card-header data-background-color="rose">
          <h4 class="title">Last Climate Users</h4>
          <p class="category">Origin Based on IP Address</p>
        </md-card-header>
        <md-card-content>
          <last-climate-users-table
            table-header-color="orange"
            :last-climate-users-data="lcu"
          ></last-climate-users-table>
        </md-card-content>
      </md-card>
    </div>
<!-- 
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <chart-card
        header-animation="true"
        :chart-data="emailsSubscriptionChart.data"
        :chart-options="emailsSubscriptionChart.options"
        :chart-responsive-options="emailsSubscriptionChart.responsiveOptions"
        chart-type="Bar"
        chart-inside-header
        background-color="rose"
      >
        <md-icon slot="fixed-button">build</md-icon>
        <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button>
        <md-button class="md-simple md-just-icon" slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Change Date</md-tooltip>
        </md-button>

<template slot="content">
    <h4 class="title">
        Website Views</h4>
    <p class="category">Last Campaign Performance</p>
</template>

<template slot="footer">
    <div class="stats">
        <md-icon>access_time</md-icon>
        updated
        <animated-number :value="10"></animated-number> days ago
    </div>
</template>
      </chart-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <chart-card
        :chart-data="dailySalesChart.data"
        :chart-options="dailySalesChart.options"
        chart-type="Line"
        chart-inside-header
        background-color="green"
      >
        <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button>
        <md-button class="md-simple md-just-icon" slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Change Date</md-tooltip>
        </md-button>

<template slot="content">
    <h4 class="title">
        Daily Sales</h4>
    <p class="category">
        <span class="text-success"><i class="fas fa-long-arrow-alt-up"></i>
                          <animated-number :value="55"></animated-number>%
                        </span> increase in today sales.
    </p>
</template>

<template slot="footer">
    <div class="stats">
        <md-icon>access_time</md-icon>
        updated
        <animated-number :value="4"></animated-number> minutes ago
    </div>
</template>
      </chart-card>
    </div>

  -->   <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <chart-card
        :chart-data="dataCompletedTasksChart.data"
        :chart-options="dataCompletedTasksChart.options"
        chart-type="Line"
        chart-inside-header
        background-color="blue"
      >
        <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button>
        <md-button class="md-simple md-just-icon" slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Change Date</md-tooltip>
        </md-button>

<template slot="content">
    <h4 class="title">
        Activity</h4>
    <p class="category"></p>
</template>

<template slot="footer">
    <div class="stats">
        <md-icon>access_time</md-icon>
        Based on Log Entries (x axis is weeks)
    </div>
</template>
      </chart-card>
    </div>

 <!--    
    <div class="md-layout-item md-size-100">
      <global-sales-card header-color="green">
<template slot="header">
    <div class="card-icon">
        <md-icon>language</md-icon>
    </div>
    <h4 class="title">Global Sales by Top Locations</h4>
</template>

<template slot="content">
    <div class="md-layout">
        <div class="md-layout-item md-size-50">
            <global-sales-table></global-sales-table>
        </div>
        <div class="md-layout-item md-size-50">
            <async-world-map class="map" :data="mapData"></async-world-map>
        </div>
    </div>
</template>
      </global-sales-card>
    </div>
 -->    
 
 <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <product-card header-animation="false">
        <img
          class="img"
          slot="imageHeader"
        />
        <md-icon slot="fixed-button">build</md-icon>
<template slot="first-button">
    <md-icon>
        art_track</md-icon>
    <md-tooltip md-direction="bottom">View</md-tooltip>
</template>

<template slot="second-button">
    <md-icon>
        edit</md-icon>
    <md-tooltip md-direction="bottom">Edit</md-tooltip>
</template>

<template slot="third-button">
    <md-icon>
        close</md-icon>
    <md-tooltip md-direction="bottom">Remove</md-tooltip>
</template>
        <h4 slot="title" class="title">
<!--           <a href="https://www.windy.com/de/-Webcams/Schweiz/Wallis/Bettmeralp/Aletsch-Arena-Bettmerhorn-II/webcams/1010993718?46.411,8.061,15" target="_blank">
<img src="https://images-webcams.windy.com/18/1010993718/current/full/1010993718.jpg" width="380px">Bettmerhorn</a>    -->       
<a href="https://www.windy.com/de/-Webcams/Schweiz/Wallis/Bettmeralp/Aletsch-Arena-Bettmerhorn-II/webcams/1010993718?46.411,8.061,15" target="_blank">
<!-- <img src="https://images-webcams.windy.com/18/1010993718/current/full/1010993718.jpg" width="380px">Bettmerhorn</a> -->
<img src="https://images-webcams.windy.com/69/1565395669/current/full/1565395669.jpg" width="380px">Bettmerhorn</a>

        </h4>
        <div slot="description" class="card-description">

        </div>
<template slot="footer">
    <div class="price">
        <h4></h4>
    
    </div>
    <div class="stats">
        <p class="category">
            <md-icon>place</md-icon>
            Bettmerhorn
        </p>
    </div>
</template>
      </product-card>
    </div>


    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <product-card header-animation="false">
        <img
          class="img"
          slot="imageHeader"
          src="https://www.nara.ch/webcam/web/nara.jpg"
        />
        <md-icon slot="fixed-button">build</md-icon>
<template slot="first-button">
    <md-icon>
        art_track</md-icon>
    <md-tooltip md-direction="bottom">View</md-tooltip>
</template>

<template slot="second-button">
    <md-icon>
        edit</md-icon>
    <md-tooltip md-direction="bottom">Edit</md-tooltip>
</template>

<template slot="third-button">
    <md-icon>
        close</md-icon>
    <md-tooltip md-direction="bottom">Remove</md-tooltip>
</template>
        <h4 slot="title" class="title">
          <a href="#pablo">Nara</a>
        </h4>
        <div slot="description" class="card-description">

        </div>
<template slot="footer">
    <div class="price">
        <h4></h4>
    </div>
    <div class="stats">
        <p class="category">
            <md-icon>place</md-icon>
            Cancorì
        </p>
    </div>
</template>
      </product-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <product-card header-animation="false">
        <img
          class="img"
          slot="imageHeader"
          src="https://www.nara.ch/webcam/web/current.jpg"
        />
        <md-icon slot="fixed-button">build</md-icon>
<template slot="first-button">
    <md-icon>
        art_track</md-icon>
    <md-tooltip md-direction="bottom">View</md-tooltip>
</template>

<template slot="second-button">
    <md-icon>
        edit</md-icon>
    <md-tooltip md-direction="bottom">Edit</md-tooltip>
</template>

<template slot="third-button">
    <md-icon>
        close</md-icon>
    <md-tooltip md-direction="bottom">Remove</md-tooltip>
</template>
        <h4 slot="title" class="title">
          <a href="#pablo">Nara</a>
        </h4>
        <div slot="description" class="card-description">
   
        </div>
<template slot="footer">
    <div class="price">
        <h4></h4>
    </div>
    <div class="stats">
        <p class="category">
            <md-icon>place</md-icon>
            Basso di Nara
        </p>
    </div>
</template>
      </product-card>
    </div>
  </div>
</template>

<script>
import AsyncWorldMap from "@/components/WorldMap/AsyncWorldMap.vue";
import Vue from "vue";
import {
    StatsCard,
    MeteoCard,
    ChartCard,
    ProductCard,
    // AnimatedNumber,
    // GlobalSalesCard,
    // GlobalSalesTable,
    TopChannelsTable,
    LastClimateUsersTable,
} from "@/components";

export default {
    components: {
        StatsCard,
        MeteoCard,
        ChartCard,
        // AnimatedNumber,
        ProductCard,
        // GlobalSalesCard,
        // GlobalSalesTable,
        // AsyncWorldMap,
        TopChannelsTable,
        LastClimateUsersTable
    },
    data() {
        return {
            tcd: [],
            lcu: [],
            stat: {},
            luftdatenVolta: {},
            luftdatenLax: {},
            luft: "",
            climaproz: 50,

            product1: "./img/card-2.jpg",
            product2: "./img/card-3.jpg",
            product3: "./img/card-1.jpg",
            seq2: 0,
            mapData: {
                AU: 760,
                BR: 550,
                CA: 120,
                DE: 1300,
                FR: 540,
                GB: 690,
                GE: 200,
                IN: 200,
                RO: 600,
                RU: 300,
                US: 2920,
            },
            dailySalesChart: {
                data: {
                    labels: ["M", "T", "W", "T", "F", "S", "S"],
                    series: [
                        [12, 17, 7, 17, 23, 18, 38]
                    ],
                },
                options: {
                    lineSmooth: this.$Chartist.Interpolation.cardinal({
                        tension: 0,
                    }),
                    low: 0,
                    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                    chartPadding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },
            dataCompletedTasksChart: {
                data: {
                    labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
                    series: [
                        [230, 750, 450, 300, 280, 240, 200, 190]
                    ],
                },

                options: {
                    lineSmooth: this.$Chartist.Interpolation.cardinal({
                        tension: 0,
                    }),
                    low: 0,
                    high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                    showPoint: false,
                    chartPadding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },
            pieChart: {
                data: {
                    labels: ["Climate", "UmMiUme"],
                    series: [67, 33],
                },
                options: {
                    height: "230px",
                },
            },
            emailsSubscriptionChart: {
                data: {
                    labels: [
                        "Ja",
                        "Fe",
                        "Ma",
                        "Ap",
                        "Mai",
                        "Ju",
                        "Jul",
                        "Au",
                        "Se",
                        "Oc",
                        "No",
                        "De",
                    ],
                    series: [
                        [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
                    ],
                },
                options: {
                    axisX: {
                        showGrid: false,
                    },
                    low: 0,
                    high: 1000,
                    chartPadding: {
                        top: 0,
                        right: 5,
                        bottom: 0,
                        left: 0,
                    },
                },
                responsiveOptions: [
                    [
                        "screen and (max-width: 640px)",
                        {
                            seriesBarDistance: 5,
                            axisX: {
                                labelInterpolationFnc: function(value) {
                                    return value[0];
                                },
                            },
                        },
                    ],
                ],
            },
        };
    },
    created() {
        // fetch on init
        //  this.fetchData();
        this.fetchTopChannels();
        this.fetchStat();
        this.fetchLuft();
        this.fetchDataLogs();
        this.fetchLastClimateDigramUsersChannels();
    },

    watch: {
        // re-fetch whenever currentBranch changes
        currentBranch: "fetchData",
    },

    methods: {

        gmlink() {
            return "https://maps.google.com/?ie=UTF8&hq=&ll=" + this.stat.mean_lat_1000 + "," + this.stat.mean_lon_1000 + "&z=13";

            // http://maps.google.com/?ie=UTF8&hq=&ll=35.028028,-106.536655&z=13
        },

        async fetchData() {
            const url = `${API_URL}${this.currentBranch}`;
            this.commits = await (await fetch(url)).json();
        },

        async fetchLuft() {
            const url1 = `https://my.mapresso.com/dashboard/src/cards/luftdatendata.php?station=Volta`;
            this.luftdatenVolta = await (await fetch(url1)).json();
            const url2 = `https://my.mapresso.com/dashboard/src/cards/luftdatendata.php?station=Lax`;
            this.luftdatenLax = await (await fetch(url2)).json();
        },

        async fetchLuft0() {
            const url = `https://my.mapresso.com/dashboard/src/cards/luftdaten3.php`;
            try {
                // Make an HTTP request or use your preferred method to fetch HTML content
                const response = await fetch(url);
                const html = await response.text();

                // Update the data property to contain the fetched HTML content
                this.luft = html;
            } catch (error) {
                // console.error('Error fetching HTML content:', error);
            }
        },

        async fetchStat() {
            const url = `https://ummiume.mapresso.com/backend/evaluation/log_stat.php`;
            this.stat = await (await fetch(url)).json();
            this.climaproz = this.stat.percentage_of_climate_diagrams;

            this.$nextTick(() => {
                let cp = +this.stat.percentage_of_climate_diagrams;
                Vue.set(this.pieChart, "data", {
                    labels: ["Climate " + Math.round(cp) + "%", "UmMiUme"],
                    series: [cp, 100 - cp]
                });
            });
        },


        async fetchDataLogs() {
            const url = `https://ummiume.mapresso.com/backend/evaluation/log_total.php`;

            fetch(url)
                .then((response) => response.text())
                .then((csvData) => {
                    const dataArray = this.parseCSVData(csvData);
                    const myArray = dataArray.series[0];
                    const filteredArray = myArray.filter((value) => !isNaN(value)); // Filter out NaN values
                    let maxValue = Math.max(...filteredArray);
                    maxValue *= 1.1;
                    let options = this.dataCompletedTasksChart.options;
                    options.high = Math.round(maxValue);

                    Vue.set(this.dataCompletedTasksChart, "options", options);

                    this.$nextTick(() => {
                        Vue.set(this.dataCompletedTasksChart, "data", dataArray);
                    });
                })
                .catch((error) => {
                    // console.error("Error fetching CSV data:", error);
                });
        },

        async fetchTopChannels() {
            const url = `https://ummiume.mapresso.com/backend/evaluation/log_top_channels.php`;
            this.tcd = await (await fetch(url)).json();
        },

        async fetchLastClimateDigramUsersChannels() {
            const url = `https://ummiume.mapresso.com/backend/evaluation/log_last_climate_users.php`;
            this.lcu = await (await fetch(url)).json();
        },

        truncate(v) {
            const newline = v.indexOf("\n");
            return newline > 0 ? v.slice(0, newline) : v;
        },
        formatDate(v) {
            return v.replace(/T|Z/g, " ");
        },
        parseCSVData(csvData) {
            const lines = csvData.split("\n");
            const result = [];

            // Assuming the first row contains the column headers
            const headers = lines[0].split(",");
            const weeks = [];
            const n = [];
            const nn = [];
            for (let i = 1; i < lines.length; i++) {
                const line = lines[i].split(",");
                if (parseInt(line[0]) % 20 == 0) {
                    weeks.push(parseInt(line[0]));
                } else {
                    weeks.push("");
                }
                n.push(parseInt(line[1]));
            }
            nn.push(n);
            const data = {
                labels: weeks,
                series: nn,
            };
            return data;
        },
    },
};
</script>

<style>
.md-ripple {
  color: red
  padding: 5 5 !important;
}